// src/theme/theme.js

import { createTheme } from '@mui/material/styles';

const nightWatchTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#4a5c66',
    },
    secondary: {
      main: '#7b8f99',
    },
    background: {
      default: '#121212',
      paper: '#1e272c',
    },
    text: {
      primary: '#e0e0e0',
      secondary: '#adb5bd',
    }
  },
  // Add any other theme customizations here
});

export default nightWatchTheme;
