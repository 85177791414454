export const processTransaction = (tx) => {
  const txTypeData = determineTransactionType(tx);
  const transactionDetails = extractTransactionDetails(tx, txTypeData.txType);

  return {
    id: tx?.id, // Include the id field here
    ...txTypeData,
    ...transactionDetails,
  };
};

const determineTransactionType = (tx) => {
  // Logic to determine the transaction type and tx_direction
  let txType = "";
  let tx_direction = "";

  // Using your existing conditions
  const isVoteYes = tx?.tx?.body?.messages?.[0]?.msg?.vote?.vote === "yes";
  const isVoteNo = tx?.tx?.body?.messages?.[0]?.msg?.vote?.vote === "no";
  const isNFTTransfer =
    tx.tx.body.messages[0]?.msg?.transfer_nft &&
    !tx.tx.body.messages[0]?.msg?.vote?.vote;
  const isSayveTransfer =
    tx.tx.body.messages[0]?.msg?.transfer &&
    !isNFTTransfer &&
    !tx.tx.body.messages[0]?.msg?.vote?.vote;
  const isLingoTransfer =
    tx.tx.body.messages[0]?.msg?.execute &&
    tx.tx.body.messages[0]?.msg.execute?.action === "mint" &&
    !tx.tx.body.messages[0]?.msg?.vote?.vote;

  const isSayveTransferOut = tx.logs.some((log) =>
    log.events.some(
      (event) =>
        event.type === "execute" &&
        event.attributes.some(
          (attr) =>
            attr.key === "_contract_address" &&
            attr.value ===
              "terra1xp9hrhthzddnl7j5du83gqqr4wmdjm5t0guzg9jp6jwrtpukwfjsjgy4f3"
        )
    )
  );
  const isNFTTransferOut =
    isVoteYes &&
    tx.logs
      .flatMap((log) => log.events)
      .some(
        (event) =>
          event.type === "wasm" &&
          event.attributes.some(
            (attr) => attr.key === "action" && attr.value === "transfer_nft"
          )
      );
  const isPropose =
    tx.tx.body.messages[0]?.msg?.propose &&
    !tx.tx.body.messages[0]?.msg?.vote?.vote;

  // Add your conditions for determining the transaction type
  if (isVoteYes) {
    if (isSayveTransferOut) {
      txType = "Sayve Transfer Voted Yes";
      tx_direction = "Vox to Station $SAYVE";
    } else if (isNFTTransferOut) {
      txType = "NFT Transfer Voted Yes";
      tx_direction = "Vox to Station NFT";
    } else txType = `Vote ${tx?.tx?.body?.messages?.[0]?.msg?.vote?.vote}`;
  } else if (isVoteNo) {
    txType = `Vote ${tx?.tx?.body?.messages?.[0]?.msg?.vote?.vote}`;
  } else if (isNFTTransfer) {
    txType = "NFT Transfer";
    tx_direction = "Station to Vox NFT";
  } else if (isSayveTransfer) {
    txType = "Sayve Transfer";
    tx_direction = "Station to Vox $SAYVE";
  } else if (isLingoTransfer) {
    txType = "Lingo Transfer";
  } else if (isPropose) {
    const splittedPropose =
      tx.tx.body?.messages[0]?.msg?.propose?.title?.split(/\s+/);
    if (splittedPropose && splittedPropose.length > 2) {
      splittedPropose.shift();
      txType = `Propose ${splittedPropose.join(" ")}`;
    } else {
      txType = "Propose";
    }
  }
  // Add other conditions as needed

  return { txType, tx_direction };
};

const extractTransactionDetails = (tx, txType) => {
  let amount = "N/A",
    sender = "N/A",
    recipient = "N/A";

  switch (txType) {
    case "Vote yes":
    case "Vote no":
      amount = tx?.tx?.body?.messages?.[0]?.msg?.vote?.proposal_id || "N/A";
      sender = tx.tx.body.messages[0]?.sender || "N/A";
      recipient = tx.tx.body.messages[0]?.msg.transfer?.recipient || "N/A";
      break;
    case "Propose upgrade NFT":
    case "Propose transfer NFT":
    case "Propose transfer sayve":
    case "Propose transfer lingo":
    case "Propose":
      amount =
        JSON.parse(tx.raw_log)[0]?.events[2]?.attributes[3]?.value || "N/A";
      sender = tx.tx.body.messages[0]?.sender || "N/A";
      recipient = tx.tx.body.messages[0]?.msg.transfer?.recipient || "N/A";
      break;
    case "Lingo Transfer":
    case "Lingo Transfer Voted Yes":
    case "Sayve Transfer":
      // Extract common logic for these types if applicable
      amount = tx.tx.body.messages[0]?.msg.transfer?.amount || "N/A";
      if (amount !== "N/A") {
        amount = `$${(parseFloat(amount) / 1000000).toFixed(2)}`;
      }
      sender = tx.tx.body.messages[0]?.sender || "N/A";
      recipient = tx.tx.body.messages[0]?.msg.transfer?.recipient || "N/A";
      break;
    case "NFT Transfer":
      // Extract for NFT Transfer without division
      amount = tx.tx.body.messages[0]?.msg.transfer_nft?.token_id || "N/A";
      sender = tx.tx.body.messages[0]?.sender || "N/A";
      recipient = tx.tx.body.messages[0]?.msg.transfer_nft?.recipient || "N/A";
      break;
    case "Sayve Transfer Voted Yes":
      // Extract for Sayve Transfer Voted Yes with division
      const wasmEventSayve = tx.logs
        .flatMap((log) => log.events)
        .find(
          (event) =>
            event.type === "wasm" &&
            event.attributes.some(
              (attr) => attr.key === "action" && attr.value === "transfer"
            )
        );

      if (wasmEventSayve) {
        const senderAttr = wasmEventSayve.attributes.find(
          (attr) => attr.key === "from"
        );
        const recipientAttr = wasmEventSayve.attributes.find(
          (attr) => attr.key === "to"
        );
        const amountAttr = wasmEventSayve.attributes.find(
          (attr) => attr.key === "amount"
        );

        sender = senderAttr ? senderAttr.value : "N/A";
        recipient = recipientAttr ? recipientAttr.value : "N/A";
        amount = amountAttr
          ? `$${(parseFloat(amountAttr.value) / 1000000).toFixed(2)}`
          : "N/A";
      }
      break;
    case "NFT Transfer Voted Yes":
      // Extract for these types without division
      const actionType =
        txType === "Sayve Transfer Voted Yes" ? "transfer" : "transfer_nft";
      const wasmEvent = tx.logs
        .flatMap((log) => log.events)
        .find(
          (event) =>
            event.type === "wasm" &&
            event.attributes.some(
              (attr) => attr.key === "action" && attr.value === actionType
            )
        );

      if (wasmEvent) {
        const keyPrefix =
          txType === "Sayve Transfer Voted Yes" ? "from" : "sender";
        const senderAttr = wasmEvent.attributes.find(
          (attr) => attr.key === keyPrefix
        );
        const recipientAttr = wasmEvent.attributes.find(
          (attr) => attr.key === (keyPrefix === "from" ? "to" : "recipient")
        );
        const amountAttr = wasmEvent.attributes.find(
          (attr) => attr.key === (keyPrefix === "from" ? "amount" : "token_id")
        );

        sender = senderAttr ? senderAttr.value : "N/A";
        recipient = recipientAttr ? recipientAttr.value : "N/A";
        amount = amountAttr ? amountAttr.value : "N/A";
      }
      break;
    default:
      // Handle unexpected transaction types or just leave it empty
      break;
  }

  return { amount, sender, recipient };
};
