import React, { useMemo, useState } from "react";
import Pagination from "./Pagination";
import { MaterialReactTable } from "material-react-table";
import { processTransaction } from "../utils/transactionUtils";
import axios from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
const BASE_URL =
  "https://phoenix-lcd.terra.dev/cosmwasm/wasm/v1/contract/terra1ut05tzut8glnpqpnm304mkns0gq3j5crsdkzlpnmgw4uhh966qus37xjkg/smart/";

// ... other imports ...

const CONTRACT_ADDRESS =
  "terra1ut05tzut8glnpqpnm304mkns0gq3j5crsdkzlpnmgw4uhh966qus37xjkg";
const FRIENDLY_NAME = "Gate Hodlor";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 10,
  whiteSpace: "normal",
  wordBreak: "break-all",
  minHeight: "80%",
};

const TransactionTable = ({ data, onPageChange, next, isLoading }) => {
  const [open, setOpen] = React.useState(false);
  const [decodedMessage, setDecodedMessage] = useState("");
  const [contractinproposal, setContractinproposal] = useState("");
  // Process each transaction
  const processedData = data.map(tx => {
    const processedTx = processTransaction(tx);
    // Replace contract address with friendly name
    if (processedTx.sender === CONTRACT_ADDRESS) {
      processedTx.sender = FRIENDLY_NAME;
    }
    if (processedTx.recipient === CONTRACT_ADDRESS) {
      processedTx.recipient = FRIENDLY_NAME;
    }
    return processedTx;
  });
  const getProposalDetails = async (proposalId) => {
    try {
      setOpen(true);
      const payloadBase64 = btoa(
        JSON.stringify({ proposal: { proposal_id: Number(proposalId) } })
      );
      const response = await axios.get(`${BASE_URL}${payloadBase64}`);
      const proposalDetails = response.data.data;
      // Decode base64 message
      const base64Message = proposalDetails.msgs[0]?.wasm?.execute?.msg;
      const decodedMessageValue = base64Message ? atob(base64Message) : "";
      const contractinproposalValue =
        proposalDetails.msgs[0]?.wasm?.execute?.contract_addr;
      setDecodedMessage(decodedMessageValue);
      setContractinproposal(contractinproposalValue);
    } catch (error) {
      console.error("Error fetching proposal details:", error);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "txType",
        header: "Type",
        Cell: ({ cell }) => (
          <div
            style={
              cell.getValue().startsWith("Propose") ? { cursor: "pointer" } : {}
            }
            onClick={() => {
              if (cell.getValue().startsWith("Propose")) {
                getProposalDetails(cell?.row?.original?.amount);
              }
            }}
          >
            {cell.getValue()}
          </div>
        ),
      },
      {
        accessorKey: "amount",
        header: "Amount",
      },
      {
        accessorKey: "sender",
        header: "Sender",
        Cell: ({ cell }) =>
          cell.getValue() === CONTRACT_ADDRESS
            ? FRIENDLY_NAME
            : cell.getValue(),
      },
      {
        accessorKey: "recipient",
        header: "Recipient",
        Cell: ({ cell }) =>
          cell.getValue() === CONTRACT_ADDRESS
            ? FRIENDLY_NAME
            : cell.getValue(),
      },
      {
        accessorKey: "tx_direction",
        header: "Direction",
      },
    ],
    []
  );

  return (
    <>
      <Pagination action={onPageChange} next={next} loading={isLoading}>
        <MaterialReactTable
          columns={columns}
          data={processedData}
          // Additional options here
        />
      </Pagination>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setDecodedMessage("");
          setContractinproposal("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIcon
            style={{
              float: "right",
              cursor: "pointer",
            }}
            onClick={() => {
              setOpen(false);
              setDecodedMessage("");
              setContractinproposal("");
            }}
          />
          {decodedMessage ? (
            <>
              {" "}
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Decoded Message:
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                {decodedMessage}
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ mt: 2 }}
              >
                Contract in Proposal:
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                {contractinproposal}
              </Typography>{" "}
            </>
          ) : (
            <Box
              sx={{
                height: "100%",
                minWidth: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                Loading...
              </Typography>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default TransactionTable;
