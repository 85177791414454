// PaginationButtons.js

import React from 'react';
import s from './PaginationButtons.module.scss'; // Make sure to define appropriate styles in your SCSS file

const PaginationButtons = ({ action, offset, loading }) => {
  return (
    <div className={s.component}>
      {offset && (
        <button
          onClick={() => action && offset && action(offset)}
          className={s.button}
          disabled={loading}
        >
          {loading ? "Loading..." : "More"}
        </button>
      )}
    </div>
  );
};

export default PaginationButtons;
