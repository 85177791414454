import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import useFetchData from './hooks/useFetchData';
import TransactionTable from './components/TransactionTable';
import nightWatchTheme from './theme/theme';

function App() {
  const { data, isLoading, hasError, offset, setOffset } = useFetchData();

  const handleMoreClick = () => {
    setOffset(offset + 10); // Assuming each page fetches 10 transactions
  };

  if (isLoading) {
    return (
      <ThemeProvider theme={nightWatchTheme}>
        <CssBaseline />
        <div>Loading...</div>
      </ThemeProvider>
    );
  }

  if (hasError) {
    return (
      <ThemeProvider theme={nightWatchTheme}>
        <CssBaseline />
        <div>Error fetching data</div>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={nightWatchTheme}>
      <CssBaseline />
      <div className="App">
        <header className="App-header">
          <h1>Night's GateWatch</h1>
          <img src="/logo.png" alt="Night's Watch Banner" className="App-logo" />
        </header>
        <TransactionTable data={data} />
        <button onClick={handleMoreClick}>Load More</button> {/* Button to load more transactions */}
      </div>
    </ThemeProvider>
  );
}

export default App;
