// Pagination.js

import React from 'react';
import PaginationButtons from './PaginationButtons';

const Pagination = ({ children, action, next, loading }) => {
  return (
    <>
      {children}
      <PaginationButtons action={action} offset={next} loading={loading} />
    </>
  );
};

export default Pagination;
