import { useState, useEffect, useCallback } from "react";
import axios from "axios";

function useFetchData() {
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [rateLimited, setRateLimited] = useState(false);
  const waitFor = () => {
    return new Promise((res, rej) => {
      setTimeout(() => {
        res();
      }, 1000);
    });
  };
  const fetchData = useCallback(async () => {
    if (rateLimited) {
      console.log("Pausing requests due to rate limiting.");
      return;
    }

    setIsLoading(true);
    setHasError(false);

    try {
      await waitFor();
      const response = await axios.get(
        `https://phoenix-fcd.terra.dev/v1/txs?offset=${offset}&limit=10&account=terra1ut05tzut8glnpqpnm304mkns0gq3j5crsdkzlpnmgw4uhh966qus37xjkg`
      );
      setData((prevData) => [...prevData, ...response.data.txs]);

      // Update offset for the next fetch
      if (response.data.next) {
        setOffset(response.data.next);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setHasError(true);
      if (error.response && error.response.status === 429) {
        setRateLimited(true);
        setTimeout(() => setRateLimited(false), 30000);
      }
    } finally {
      setIsLoading(false);
    }
  }, [offset, rateLimited]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, isLoading, hasError, offset, setOffset };
}

export default useFetchData;
